@charset "UTF-8";

/******************* Color ********************/
@mixin selection {
    color: $select_text;
    background: $select_text_bg;
}

* {
    &::-webkit-selection {
        @include selection;
    }
    
    &::-moz-selection {
        @include selection;
    }
    
    &::selection {
        @include selection;
    }
}

h1,
h2,
h3,
h4,
h5,
h6,
ol,
ul,
p {
    color: $text;
}

body {
    background-color: $page_background;
}

h2.title-h2 {
    background-color: $info_background;
}

a {
    color: $link;
}

blockquote {
    background-color: $info_background;
    border-color: $border;
    
    p {
        color: $text;
    }
}

.kratos-hentry {
    background-color: $tag_background;
}

.kratos-entry-border-new {
    a {
        color: $text;
    }
    
    .kratos-post-meta-new {
        border-top: 1px solid $border;
        background: $tag_background;
    }

    .kratos-status {
        i {
            color: $text_alt;
        }

        p a {
            color: $link;
    
            &:hover {
                color: $link_hover;
            }
        }
    }
}

.kratos-entry-header {
    span, a {
        color: $text;
    }
}

ul.kratos-post-meta {
    color: $text_alt;
    span, a {
        color: $text_alt;
    }
}

.kratos-post-content {
    p, table td {
    color: $text;
    }
}

.kratos-post-inner {
    .kratos-copyright {
        background-color: $info_background;

        h5 {
            color: $text;
        }

        a {
            color: $text;
        }
    }
}

.kratos-entry-footer {
    .footer-tag a {
        color: $text;
        background-color: $tag_background;
    }

    .pull-date span {
        color: $text;
    }
}

.post-navigation a {
    color: $text;
}

#kratos-widget-area {

    .widget {
        a, &-title i {
            color: $text_alt;
        }

        &.widget-kratos {
            &-about .site-meta > .meta-item:hover {
                background-color: $info_background;
            }

            &-tags {
                .tag-clouds a {
                    background-color: $tag_background;
            
                    &:hover {
                        color: $tag_background;
                        background-color: $text_alt;
                    }
                }
            }
        
            &-categories .category-list-item {
                &::before {
                    color: $text_alt;
                }
                .category-list-count {
                    color: $text;
                    background-color: $info_background;
                }
            }
        }
    }

}

.pagination {
    a, span {
        background-color: $tag_background;
        color: $text;
    }

    > li > a {
        background-color: $card_background;
    }
}

.kratos-hentry, .navigation div, #kratos-widget-area .widget {
    background-color: $card_background;
}

.arc-tags a {
    color: $text_alt !important;
    background-color: $tag_background !important;
    border-color: $border !important;

    &:hover {
        color: $link_hover !important;
    }
}

.linkpage ul li {
    a h4 {
        color: $text;
    }

    &:hover {
        background-color: $info_background;
    }
}

.kratos-post-header-title {
    color: $text;
}

.xControl {
    background-color: $info_background;

    .xHeading span {
        color: $text;
    }
}

.index-post .kratos-entry-header .kratos-entry-title {
    color: $text;

    &::after {
        background-color: $text;
    }
}

#archives .posts-collapse .post-title a,
.categories > ul.category-list > li.category-list-item a {
    color: $text_alt;
}

.post-comments {
    background-color: $tag_background;
}

a:hover,
.kratos-entry-border-new a:hover,
.kratos-entry-header .kratos-post-meta a:hover,
.kratos-post-inner .kratos-copyright a:hover,
#footer li a:hover,
#archives .posts-collapse .post-title a:hover {
    color: $link_hover;
}

#kratos-desktop-topnav {
    background: $navbar_desktop;
}

#kratos-mobile-topnav {
    background: $navbar_mobile;
}

.toc-item.active>a {
  color: $tag_background;

  &::before {
    border-left-color: $tag_background;
  }
}

span.blur {
  text-shadow: 0 0 .5em $text;

  &:hover {
    color: $text!important;
  }
}

/******************* Plugins ********************/

#dsqjs {
    a {
        color: $link;
    }

    .dsqjs-tab-active {
        color: $text;
    }

    &-msg {
        color: $text;
        background-color: $tag_background;
    }
}

.post-comments {
    .v[data-class=v] {
        p, 
        .vheader label, 
        .veditor, .veditor::placeholder {
            color: $text;
        }

        .vbtn,
        .vcount,
        .vcard .vhead span.vnick,
        .vcard .vhead .vtime {
            color: $text_alt;
        }

        .vicon,
        .vcard .vhead .vreply svg {
            fill: $text_alt;
        }

        .vpanel {
            background-color: $tag_background;
        }

        .veditor:focus, .vinput:focus {
            background-color: $info_background;
        }
    }
}

.aplayer * {
    color: unset;
}

span.mjx-chtml.MJXc-display {
    color: $text;
}



/******************* Images ********************/
.widget-kratos-about .photo-background {
    background-image: $about_image;
}

.kratos-cover.kratos-cover-2 {
    background-image: $banner_image;
}

@media(min-width:768px) {
    body.custom-background {
        background-image: $background-image;
    }
}

.post-comments.bg-image textarea {
    background: $comment-background-image right bottom no-repeat!important;
}
